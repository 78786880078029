.logos {
    max-width: 100%;
    max-height: 80px;
    width: auto;
    height: auto;
}

/* Smaller screens */
@media (max-width: 768px) {
    .logos {
        max-height: 50px;
    }
}

/* Extra small screens */
@media (max-width: 480px) {
    .logos {
        max-height: 40px;
    }
}
